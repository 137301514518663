import { FormatSizeWithUnits } from "@app/ui/format-size-with-units"
import { useI18n } from "next-localization"
import { FC } from "react"

import styles from "./single-scene-info.module.scss"

type SingleSceneInfoProps = {
  photos: number
  weight: number
}
const SingleSceneInfo: FC<SingleSceneInfoProps> = ({ photos, weight }) => {
  const { t } = useI18n()

  return (
    <div className={styles["root"]}>
      <span className={styles["label"]}>{t("downloadModal.downloadAllPhotos")}</span>
      <span className={styles["info"]}>
        {t("downloadModal.photosQuantity", { value: photos })}
        {weight !== null && <FormatSizeWithUnits prefix=", " size={weight} />}
      </span>
    </div>
  )
}

export { SingleSceneInfo }
