import create from "zustand"

interface HidingPhotosAuthStore {
  isUserAuthorizedHidingPhotos: boolean
  setUserAuthorizationHidingPhotos: (status: boolean) => void
}

const useHidingPhotosAuthStore = create<HidingPhotosAuthStore>((set) => ({
  isUserAuthorizedHidingPhotos: false,
  setUserAuthorizationHidingPhotos: (status) => set(() => ({ isUserAuthorizedHidingPhotos: status })),
}))

const isUserAuthorizedHidingPhotosSelector = (state: HidingPhotosAuthStore) => state.isUserAuthorizedHidingPhotos
const setUserAuthorizationHidingPhotosSelector = (state: HidingPhotosAuthStore) =>
  state.setUserAuthorizationHidingPhotos

function useHidingPhotosAuth() {
  const isUserAuthorizedHidingPhotos = useHidingPhotosAuthStore(isUserAuthorizedHidingPhotosSelector)
  const setUserAuthorizationHidingPhotos = useHidingPhotosAuthStore(setUserAuthorizationHidingPhotosSelector)

  return { isUserAuthorizedHidingPhotos, setUserAuthorizationHidingPhotos }
}

export { useHidingPhotosAuth }
