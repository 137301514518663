import create from "zustand"

const useManualDownloadModalStore = create((set) => ({
  isManualDownloadModalVisible: false,
  photo: "",
  showManualDownloadModal: (photo) => set(() => ({ photo, isManualDownloadModalVisible: true })),
  hideManualDownloadModal: () => set(() => ({ isManualDownloadModalVisible: false })),
}))

export { useManualDownloadModalStore }
