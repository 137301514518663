import { useManualDownloadModalStore } from "features/manual-download/store"

const isManualDownloadModalVisibleSelector = (state) => state.isManualDownloadModalVisible
const fileSelector = (state) => state.photo
const showManualDownloadModalSelector = (state) => state.showManualDownloadModal
const hideManualDownloadModalSelector = (state) => state.hideManualDownloadModal

function useManualDownloadModal() {
  const $isManualDownloadModalVisible = useManualDownloadModalStore(isManualDownloadModalVisibleSelector)
  const $file = useManualDownloadModalStore(fileSelector)
  const $showManualDownloadModal = useManualDownloadModalStore(showManualDownloadModalSelector)
  const $hideManualDownloadModal = useManualDownloadModalStore(hideManualDownloadModalSelector)

  return {
    $isManualDownloadModalVisible,
    $file,
    $showManualDownloadModal,
    $hideManualDownloadModal,
  }
}

export { useManualDownloadModal }
