import create from "zustand"

interface AuthorizationModalStore {
  isAuthorizationModalVisible: boolean
  isHidingPhotosModalVisible: boolean
  isHidingPhotosSignOutModalVisible: boolean
  showAuthorizationModal: () => void
  hideAuthorizationModal: () => void
  showAuthorizationModalHidingPhotos: () => void
  hideAuthorizationModalHidingPhotos: () => void
  showSignOutModalHidingPhotos: () => void
  hideSignOutModalHidingPhotos: () => void
}

const useAuthorizationModalStore = create<AuthorizationModalStore>((set) => ({
  isAuthorizationModalVisible: false,
  isHidingPhotosModalVisible: false,
  isHidingPhotosSignOutModalVisible: false,
  showAuthorizationModal: () => set(() => ({ isAuthorizationModalVisible: true })),
  hideAuthorizationModal: () => set(() => ({ isAuthorizationModalVisible: false })),
  showAuthorizationModalHidingPhotos: () => set(() => ({ isHidingPhotosModalVisible: true })),
  hideAuthorizationModalHidingPhotos: () => set(() => ({ isHidingPhotosModalVisible: false })),
  showSignOutModalHidingPhotos: () => set(() => ({ isHidingPhotosSignOutModalVisible: true })),
  hideSignOutModalHidingPhotos: () => set(() => ({ isHidingPhotosSignOutModalVisible: false })),
}))

const isAuthorizationModalVisibleSelector = (state: AuthorizationModalStore) => state.isAuthorizationModalVisible
const isHidingPhotosModalVisibleSelector = (state: AuthorizationModalStore) => state.isHidingPhotosModalVisible
const isHidingPhotosSignOutModalVisibleSelector = (state: AuthorizationModalStore) =>
  state.isHidingPhotosSignOutModalVisible
const showAuthorizationModalSelector = (state: AuthorizationModalStore) => state.showAuthorizationModal
const hideAuthorizationModalSelector = (state: AuthorizationModalStore) => state.hideAuthorizationModal
const showAuthorizationModalHidingPhotosSelector = (state: AuthorizationModalStore) =>
  state.showAuthorizationModalHidingPhotos
const hideAuthorizationModalHidingPhotosSelector = (state: AuthorizationModalStore) =>
  state.hideAuthorizationModalHidingPhotos
const showSignOutModalHidingPhotosSelector = (state: AuthorizationModalStore) => state.showSignOutModalHidingPhotos
const hideSignOutModalHidingPhotosSelector = (state: AuthorizationModalStore) => state.hideSignOutModalHidingPhotos

function useAuthorizationModal() {
  const isAuthorizationModalVisible = useAuthorizationModalStore(isAuthorizationModalVisibleSelector)
  const isHidingPhotosModalVisible = useAuthorizationModalStore(isHidingPhotosModalVisibleSelector)
  const isHidingPhotosSignOutModalVisible = useAuthorizationModalStore(isHidingPhotosSignOutModalVisibleSelector)
  const showAuthorizationModal = useAuthorizationModalStore(showAuthorizationModalSelector)
  const hideAuthorizationModal = useAuthorizationModalStore(hideAuthorizationModalSelector)
  const showAuthorizationModalHidingPhotos = useAuthorizationModalStore(showAuthorizationModalHidingPhotosSelector)
  const hideAuthorizationModalHidingPhotos = useAuthorizationModalStore(hideAuthorizationModalHidingPhotosSelector)
  const showSignOutModalHidingPhotos = useAuthorizationModalStore(showSignOutModalHidingPhotosSelector)
  const hideSignOutModalHidingPhotos = useAuthorizationModalStore(hideSignOutModalHidingPhotosSelector)

  return {
    isAuthorizationModalVisible,
    isHidingPhotosModalVisible,
    isHidingPhotosSignOutModalVisible,
    showAuthorizationModal,
    hideAuthorizationModal,
    showAuthorizationModalHidingPhotos,
    hideAuthorizationModalHidingPhotos,
    showSignOutModalHidingPhotos,
    hideSignOutModalHidingPhotos,
  }
}

export { useAuthorizationModal }
