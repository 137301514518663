import create from "zustand"

interface LimitModalStore {
  isLimitModalVisible: boolean
  showLimitModal: () => void
  hideLimitModal: () => void
}

const useLimitModalStore = create<LimitModalStore>((set) => ({
  isLimitModalVisible: false,
  showLimitModal: () => set(() => ({ isLimitModalVisible: true })),
  hideLimitModal: () => set(() => ({ isLimitModalVisible: false })),
}))

const isLimitModalVisibleSelector = (state: LimitModalStore) => state.isLimitModalVisible
const showLimitModalSelector = (state: LimitModalStore) => state.showLimitModal
const hideLimitModalSelector = (state: LimitModalStore) => state.hideLimitModal

function useLimitModal() {
  const isLimitModalVisible = useLimitModalStore(isLimitModalVisibleSelector)
  const showLimitModal = useLimitModalStore(showLimitModalSelector)
  const hideLimitModal = useLimitModalStore(hideLimitModalSelector)

  return { isLimitModalVisible, showLimitModal, hideLimitModal }
}

export { useLimitModal }
