import cx from "clsx"

import styles from "./radio.module.scss"

const Radio = ({ id, name, value, text, checked, defaultChecked, required, className, register, ...rest }: any) => {
  return (
    <label className={cx(styles["radio"], className)}>
      <input
        type="radio"
        name={name}
        checked={checked}
        defaultChecked={defaultChecked}
        value={value}
        id={id}
        className={styles["input"]}
        ref={register}
        {...rest}
      ></input>
      <span className={styles["mark-icon"]}></span>
      <span className={styles["text"]}>{text}</span>
    </label>
  )
}

export { Radio }
