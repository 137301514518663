import create from "zustand"

interface AuthorizationStore {
  isUserAuthorized: boolean
  setUserAuthorization: (status: boolean) => void
}

const useAuthorizationStore = create<AuthorizationStore>((set) => ({
  isUserAuthorized: false,
  setUserAuthorization: (status) => set(() => ({ isUserAuthorized: status })),
}))

const isUserAuthorizedSelector = (state: AuthorizationStore) => state.isUserAuthorized
const setUserAuthorizationSelector = (state: AuthorizationStore) => state.setUserAuthorization

function useAuthorization() {
  const isUserAuthorized = useAuthorizationStore(isUserAuthorizedSelector)
  const setUserAuthorization = useAuthorizationStore(setUserAuthorizationSelector)

  return { isUserAuthorized, setUserAuthorization }
}

export { useAuthorization }
