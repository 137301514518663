import { useI18n } from "next-localization"
import { FC } from "react"

const UNITS = {
  gb: 1073741824,
  mb: 1048576,
  kb: 1024,
  b: 1,
}

/**
 * Преобразует переданный size в байтах в читаемый формат и добавляет значение юнит.
 * @param {{ size }} props: Пропсы компонента
 * @returns возвращает локализованное преобразованное значение веса к ближайшему подходящему.
 */
type FormatSizeWithUnitsProps = {
  size: number
  prefix?: string
}
const FormatSizeWithUnits: FC<FormatSizeWithUnitsProps> = ({ prefix, size }) => {
  const { t } = useI18n()
  const unitsKeys = Object.keys(UNITS)
  const targetUnitKey = unitsKeys.find((unitKey) => size >= UNITS[unitKey])

  return (
    <>
      {prefix}
      {t(`size_units.${targetUnitKey}`, { value: (size / UNITS[targetUnitKey]).toFixed(1) })}
    </>
  )
}

export { FormatSizeWithUnits }
