import * as React from "react"
import { useI18n } from "next-localization"
import cx from "clsx"

import { Checkbox } from "@app/ui/checkbox"
import { useGallery } from "@app/contexts"
import { useGlobalState } from "@app/contexts"
import { useHidingPhotosAuth } from "@app/features/user/stores"
import { fetchGallery } from "features/gallery/requests"
import { useRouter } from "next/router"
import { REQUEST_TYPE } from "@app/api/constants"

import { useScenesWeight } from "@app/features/download/hooks"
import { DOWNLOAD_SCENE_TYPE } from "@app/features/download/contstants"
import { FormatSizeWithUnits } from "@app/ui/format-size-with-units"

import { SingleSceneInfo } from "../single-scene-info"
import styles from "./scene-selector.module.scss"

type SceneSelectorProps = {
  totalWeight: ReturnType<typeof useScenesWeight>[0]
  scenesWeights: ReturnType<typeof useScenesWeight>[1]
  onChange: (scenes: string[]) => void
}
const SceneSelector: React.FC<SceneSelectorProps> = ({ totalWeight, scenesWeights, onChange }) => {
  const { t } = useI18n()
  const { gallery } = useGallery()
  const { isUserAuthorizedHidingPhotos } = useHidingPhotosAuth()
  const settings = useGlobalState()
  const router = useRouter()

  const { isHidingPhotosEnabled } = settings
  const isCanBeHidden = isHidingPhotosEnabled && isUserAuthorizedHidingPhotos

  const [galleryUpdate, setGalleryUpdate] = React.useState(gallery)
  React.useEffect(() => {
    const fetchData = async () => {
      const slug = router.query.slug
      const domain = window.location.host
      const { gallery } = await fetchGallery({ requestType: REQUEST_TYPE.CLIENT, slug, domain, ctx: null })
      setGalleryUpdate(gallery)
    }
    if (isCanBeHidden) {
      fetchData().catch(console.error)
    }
  }, [])

  const [isAllScenesSelected, setIsAllScenesSelected] = React.useState(true)

  const hasSeveralScenes = Boolean(galleryUpdate.scenes && galleryUpdate.scenes.length > 1)
  let allFilteredPhotos = []

  //  если авторизован, то фильтруем только по не пустым, а если просто пользователь - фильтруем еще и по скрытым сценам
  //  разделено на два filter, чтобы читалось легче
  const filteredScenes = !isCanBeHidden
    ? galleryUpdate.scenes.filter((scene) => scene.mediaFiles.length && !scene.isAllMediaFilesHidden)
    : galleryUpdate.scenes.filter((scene) => scene.mediaFiles.length)
  const scenes = filteredScenes.map((scene) => {
    allFilteredPhotos = [...allFilteredPhotos, ...scene.mediaFiles]
    return {
      name: scene.name,
      numberOfMediaFiles: scene.mediaFiles.length,
      id: scene.id,
      selected: false,
    }
  })

  if (!hasSeveralScenes) return <SingleSceneInfo weight={totalWeight} photos={allFilteredPhotos.length} />

  const [availableScenes, setAvailableScenes] = React.useState(scenes)
  React.useEffect(() => {
    setAvailableScenes(scenes)
  }, [galleryUpdate])

  React.useEffect(() => {
    onChange(availableScenes.filter((scene) => scene.selected).map((scene) => scene.id))
  }, [availableScenes])

  function handleAllScenesSelect() {
    setIsAllScenesSelected(true)
    setAvailableScenes((selectedScenes) => {
      return selectedScenes.map((selectedScene) => {
        return {
          ...selectedScene,
          selected: false,
        }
      })
    })
  }

  function handleSceneSelect(sceneId) {
    setIsAllScenesSelected(false)
    setAvailableScenes((selectedScenes) => {
      return selectedScenes.map((selectedScene) => {
        if (selectedScene.id === sceneId) {
          return {
            ...selectedScene,
            selected: !selectedScene.selected,
          }
        }

        return selectedScene
      })
    })
  }

  return (
    <React.Fragment>
      <div
        className={cx(styles["root"], {
          [styles["bottom-shadow"]]: availableScenes.length >= 6,
        })}
      >
        <div className={styles["container"]}>
          <div className={styles["checkboxes"]}>
            <Checkbox
              text={t("downloadModal.downloadAllPhotos")}
              textStyle="bold"
              className={styles["checkbox"]}
              checked={isAllScenesSelected}
              onChange={handleAllScenesSelect}
              name={DOWNLOAD_SCENE_TYPE.ALL_PHOTOS}
            >
              <span className={styles["scene-info"]}>
                {t("downloadModal.photosQuantity", { value: allFilteredPhotos.length })}
                {totalWeight !== null && <FormatSizeWithUnits prefix=", " size={totalWeight} />}
              </span>
            </Checkbox>

            {availableScenes.map((scene) => {
              const isEmpty = !scene.numberOfMediaFiles
              const weight = scenesWeights?.get(scene.id)

              return (
                <Checkbox
                  key={scene.id}
                  text={scene.name}
                  className={styles["checkbox"]}
                  onChange={() => handleSceneSelect(scene.id)}
                  checked={scene.selected}
                  name={DOWNLOAD_SCENE_TYPE.SCENE}
                  id={scene.id}
                >
                  {!isEmpty && (
                    <span className={styles["scene-info"]}>
                      {t("downloadModal.photosQuantity", { value: scene.numberOfMediaFiles })}
                      {weight !== null && <FormatSizeWithUnits prefix=", " size={scenesWeights.get(scene.id)} />}
                    </span>
                  )}
                </Checkbox>
              )
            })}
          </div>
        </div>
      </div>
      <div className={styles["separator"]}></div>
    </React.Fragment>
  )
}

export { SceneSelector }
