import cx from "clsx"
import { useI18n } from "next-localization"

import { DOWNLOAD_LOADING, DOWNLOAD_SUCCESS, DOWNLOAD_ERROR } from "features/download/contstants"

import { Success, Error } from "ui/icons"
import { Spinner } from "ui/spinner"

import styles from "./download-result.module.scss"
import React from "react"
import { IconWrapper } from "@app/features/theme/icon-wrapper"

const DownloadResult = ({ $downloadToCloudStatus, className, ...rest }: any) => {
  const i18n = useI18n()

  if ($downloadToCloudStatus === DOWNLOAD_LOADING) {
    return (
      <div className={cx(styles["result"], className)} {...rest}>
        <div className={styles["result-icon"]}>
          <Spinner className={styles["spinner"]}></Spinner>
        </div>
        <p className={styles["result-text"]}>{i18n.t("downloadModal.statuses.loading")}</p>
      </div>
    )
  }
  if ($downloadToCloudStatus === DOWNLOAD_SUCCESS) {
    return (
      <div className={cx(styles["result"], className)} {...rest}>
        <div className={styles["result-icon"]}>
          <Success width="42" height="42"></Success>
        </div>
        <p className={styles["result-text"]}>{i18n.t("downloadModal.statuses.success")}</p>
      </div>
    )
  }
  if ($downloadToCloudStatus === DOWNLOAD_ERROR) {
    return (
      <div className={cx(styles["result"], className)} {...rest}>
        <div className={styles["result-icon"]}>
          <IconWrapper
            light={<Error width="42" height="42"></Error>}
            dark={<Error stroke="#fff" width="42" height="42"></Error>}
          />
        </div>
        <p className={styles["result-text"]}>{i18n.t("downloadModal.statuses.error")}</p>
      </div>
    )
  }
  return null
}

export { DownloadResult }
