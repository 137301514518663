import React from "react"
import { useRouter } from "next/router"

import { YANDEX_CLOUD_OAUTH_DENIED, YANDEX_CLOUD_TOKEN } from "features/download/contstants"

function YandexRedirectListener() {
  const router = useRouter()

  React.useEffect(() => {
    const isLocationHashExist = window.document.location.hash
    let isOAuthAccessDenied = null
    let isStateParamExist = null

    if (isLocationHashExist) {
      isStateParamExist = window.document.location.hash.includes("state")
      isOAuthAccessDenied = window.document.location.hash.includes("error")
    }

    // При первом успешном открытии страницы парсим сабдомен из state
    if (isLocationHashExist && isStateParamExist) {
      const stateQueryParam = /state=([^&]+)/.exec(window.document.location.hash)[1]
      if (isOAuthAccessDenied) {
        const errorQueryParam = /error=([^&]+)/.exec(window.document.location.hash)[1]
        const urlForErrorRedirect = `${process.env.NEXT_PUBLIC_PROTOCOL}${stateQueryParam}.${process.env.NEXT_PUBLIC_DOMAIN}/verification/yandex-disc/#error=${errorQueryParam}`
        // Редиректим на страницу с корректным сабдоменом и передаём ошибку
        router.push(urlForErrorRedirect)
      }
      if (!isOAuthAccessDenied) {
        const token = /access_token=([^&]+)/.exec(window.document.location.hash)[1]
        const urlForSuccessRedirect = `${process.env.NEXT_PUBLIC_PROTOCOL}${stateQueryParam}.${process.env.NEXT_PUBLIC_DOMAIN}/verification/yandex-disc#access_token=${token}`
        // Редиректим на страницу с корректным сабдоменом и передаём токен
        router.push(urlForSuccessRedirect)
      }
    }

    // Сработает после редиректа т.к. нет параметра state
    if (isLocationHashExist && !isStateParamExist) {
      if (isOAuthAccessDenied) {
        const errorQueryParam = /error=([^&]+)/.exec(window.document.location.hash)[1]
        localStorage.setItem(YANDEX_CLOUD_OAUTH_DENIED, errorQueryParam)
        window.close()
      }
      if (!isOAuthAccessDenied) {
        const isOAuthAccessDeniedExist = localStorage.getItem(YANDEX_CLOUD_OAUTH_DENIED)
        if (isOAuthAccessDeniedExist) {
          localStorage.removeItem(YANDEX_CLOUD_OAUTH_DENIED)
        }
        const token = /access_token=([^&]+)/.exec(window.document.location.hash)[1]
        localStorage.setItem(YANDEX_CLOUD_TOKEN, token)
        window.close()
      }
    }
  }, [])

  return <p style={{ textAlign: "center" }}>...</p>
}

export { YandexRedirectListener }
