import React, { useMemo, useState } from "react"
import { useI18n } from "next-localization"
import cx from "clsx"

import { useDownloadModal } from "@app/features/download/store/hooks"
import {
  useDownloadToYandex,
  useDownloadToGoogleStatusListener,
  useScenesWeight,
  DownloadFormat,
} from "@app/features/download/hooks"
import {
  getPhotoDownloadURL,
  getSelectedScenes,
  fetchGalleryDownloadURL,
  fetchSelectedScenesDownloadURL,
  fetchFavoritePhotosDownloadURL,
  downloadToCloud,
  fetchVideoDownloadURL,
} from "@app/features/download/utils"
import {
  WHOLE_GALLERY,
  SINGLE_PHOTO,
  SINGLE_VIDEO,
  MULTIPLY_SCENES,
  DOWNLOAD_CONSUMER_DEVICE,
  DOWNLOAD_CONSUMER_YANDEX,
  DOWNLOAD_CONSUMER_GOOGLE,
  DOWNLOAD_PROVIDER_GOOGLE,
  FAVORITE_PHOTOS,
  FAVORITE_LIST,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_ERROR,
} from "@app/features/download/contstants"
import { DOWNLOAD_LOADING } from "@app/features/download/contstants"
import { forceDownloadFile, useLinkVideo } from "@app/utils"
import { useManualDownloadModal } from "@app/features/manual-download/store/hooks"
import { useGlobalState, useGallery } from "@app/contexts"
import { isClient } from "@app/utils"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"
import { downloadsCountIncrement } from "@app/features/statistics"

import { DownloadConsumerRadio, DownloadResult } from "features/download/components"
import { SceneSelector } from "@app/features/download/components"
import { Modal } from "ui/modal"
import { Radio } from "ui/radio"
import { Button } from "ui/button"
import { Hint } from "ui/hint"
import { TabletDevice, YandexDisc, YandexDiscLight, GoogleDrive } from "ui/icons"

import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { useLinkImage } from "@app/utils/hooks"

import { PRODUCTS_MODAL_TYPES } from "@app/features/products/types"
import { useShowProductsModal } from "@app/features/products/hooks/products-modal"

import styles from "./download-modal.module.scss"
import { PrivateGalleryInterface, PublicGalleryInterface } from "@app/features/gallery/types"
import { FormatSizeWithUnits } from "@app/ui/format-size-with-units"

interface DownloadModalProps {
  location?: "gallery-page"
}

const DownloadModal = ({ location }: DownloadModalProps) => {
  const {
    $isDownloadModalVisible,
    $hideDownloadModal,
    $downloadTarget,
    $downloadToCloudStatus,
    $resetDownloadToCloudStatus,
    $resetSelectedPhotoType,
    $setDownloadToCloudStatus,
  } = useDownloadModal()

  const i18n = useI18n()

  const isPhoto = $downloadTarget?.target === SINGLE_PHOTO
  const isVideo = $downloadTarget?.target === SINGLE_VIDEO
  const textDownload = isVideo ? i18n.t("common.downloadVideo") : i18n.t("common.downloadPhotos")

  const submitRef = React.useRef<any>(null)
  const accessToken = React.useRef<string | null>(null)

  useDownloadToGoogleStatusListener(submitRef.current, accessToken)

  const {
    serviceDomain,
    domain,
    locale,
    size,
    imageProxyHost,
    userAgent: { isMobile, isIos },
  } = useGlobalState()
  const { $showManualDownloadModal } = useManualDownloadModal()
  const $showProductsModal = useShowProductsModal()

  const { gallery } = useGallery()
  const downloadOptions = {
    device: gallery.settings.downloadOptions?.hasOwnProperty("device") ? gallery.settings.downloadOptions.device : true,
    yandex: gallery.settings.downloadOptions?.hasOwnProperty("yandex") ? gallery.settings.downloadOptions.yandex : true,
    google: gallery.settings.downloadOptions?.hasOwnProperty("google") ? gallery.settings.downloadOptions.google : true,
  }

  const isPrintableProductsEnabled = gallery?.isPrintableProductsEnabled

  const downloadToYandex = useDownloadToYandex(serviceDomain)

  const [isDownloadToGoogleEnable, setIsDownloadToGoogleEnable] = React.useState(false)

  const hasIntentionToDownloadGallery = location === "gallery-page" && $downloadTarget?.target === WHOLE_GALLERY

  const [isGoogleDownloadTypeActive, setIsGoogleDownloadTypeActive] = React.useState(false)
  function enableGoogleDownloadType() {
    setIsGoogleDownloadTypeActive(true)
  }

  function disableGoogleDownloadType() {
    setIsGoogleDownloadTypeActive(false)
  }

  const getSrcImage = function (size, linkImage, imageProxyHost) {
    const imageSizeFormat = `=s${size}`

    return linkImage.includes(imageProxyHost) ? linkImage : `${linkImage[0]}${imageSizeFormat}`
  }

  const isOneImageDownload = isPhoto || isVideo
  const file_path = isOneImageDownload ? $downloadTarget?.sizes[0] : null

  const linkImage = useLinkImage(size?.web, file_path, $downloadTarget?.sizes)

  const originalSize = isMobile && isIos ? size?.originalIos : size?.original
  const srcImageOrigin = getSrcImage(originalSize, linkImage, imageProxyHost)
  const srcImageWeb = getSrcImage(size?.web, linkImage, imageProxyHost)

  const srcImage = { original: srcImageOrigin, web: srcImageWeb }

  // const srcVideo = useLinkVideo(file_path)

  const [downloadType, setDownloadType] = useState<DownloadFormat>("original")

  const handleChangeDownloadType = ({ target }) => {
    target.checked && setDownloadType(target.value)
  }

  // TODO: Переделать на react hook form
  async function handleSubmit(event) {
    event.preventDefault()
    const consumer = event.target.elements.consumer
    const consumers = consumer.length > 1 ? [...consumer] : [consumer]

    const selectedConsumer = consumers.find((consumer) => Boolean(consumer.checked))

    const isConsumerEqDevice = selectedConsumer.value === DOWNLOAD_CONSUMER_DEVICE
    const isConsumerEqYandex = selectedConsumer.value === DOWNLOAD_CONSUMER_YANDEX
    const isConsumerEqGoogle = selectedConsumer.value === DOWNLOAD_CONSUMER_GOOGLE

    const selectedScenes = getSelectedScenes(event.target.elements)

    if ($downloadTarget.target === "gallery") {
      downloadsCountIncrement($downloadTarget.id)
    }

    if (isPrintableProductsEnabled && $downloadTarget.target !== "photo" && $downloadTarget.target !== "video") {
      $showProductsModal(PRODUCTS_MODAL_TYPES.DOWNLOAD)
    }

    if (isConsumerEqDevice) {
      const radios = !isVideo && [...event.target.elements.type]

      const selectedRadio = !isVideo ? radios.find((radio) => Boolean(radio.checked)) : { value: "original" }

      const locationOrigin = window.location.origin
      if (hasIntentionToDownloadGallery) {
        const hasSeveralScenesToDownload = Array.isArray(selectedScenes)

        if (selectedRadio.value === "web") {
          if (isMobile) {
            trackYandexMetrikaAction("click-radio-button-web-format-download-archive-mobile")
          }

          if (!isMobile) {
            trackYandexMetrikaAction("click-radio-button-web-format-download-archive-desktop")
          }
        }

        if (selectedRadio.value === "original") {
          if (isMobile) {
            trackYandexMetrikaAction("click-radio-button-original-format-download-archive-mobile")
          }

          if (!isMobile) {
            trackYandexMetrikaAction("click-radio-button-original-format-download-archive-desktop")
          }
        }

        /**
         * Скачивание выбранных сцен
         */
        if (hasSeveralScenesToDownload) {
          const downloadURL = await fetchSelectedScenesDownloadURL({
            locationOrigin,
            selectedScenes,
            selectedDownloadSettings: selectedRadio.value,
          })

          if (downloadURL) {
            forceDownloadFile(downloadURL)
          }
        }

        /**
         * Скачивание всей галереи целиком
         */
        if (!hasSeveralScenesToDownload) {
          const downloadURL = await fetchGalleryDownloadURL({
            locationOrigin,
            galleryId: $downloadTarget.id,
            selectedDownloadSettings: selectedRadio.value,
          })

          if (downloadURL) {
            forceDownloadFile(downloadURL)
          }
        }
      }

      /**
       * Скачивание одного фото
       */
      if ($downloadTarget.target === SINGLE_PHOTO) {
        if (selectedRadio.value === "web") {
          if (isMobile) {
            trackYandexMetrikaAction("click-radio-button-web-format-download-photo-full-screen-mobile")
          }

          if (!isMobile) {
            trackYandexMetrikaAction("click-radio-button-web-format-download-photo-full-screen-desktop")
          }
        }

        if (selectedRadio.value === "original") {
          if (isMobile) {
            trackYandexMetrikaAction("click-radio-button-original-format-download-photo-full-screen-mobile")
          }

          if (!isMobile) {
            trackYandexMetrikaAction("click-radio-button-original-format-download-photo-full-screen-desktop")
          }
        }

        if (isMobile && isIos) {
          $showManualDownloadModal(srcImage[selectedRadio.value])
        } else {
          const downloadURL = getPhotoDownloadURL({
            locationOrigin,
            photoId: $downloadTarget.id,
            selectedDownloadSettings: selectedRadio.value,
          })
          forceDownloadFile(downloadURL)
        }
      }

      /**
       * Скачивание одного видео
       */
      if ($downloadTarget.target === SINGLE_VIDEO) {
        if (selectedRadio.value === "original") {
          if (isMobile) {
            trackYandexMetrikaAction("click-radio-button-original-format-download-video-full-screen-mobile")
          }

          if (!isMobile) {
            trackYandexMetrikaAction("click-radio-button-original-format-download-video-full-screen-desktop")
          }
        }

        // if (isMobile && isIos) {
        //   $showManualDownloadModal(srcVideo)
        // } else {
        const downloadURL = await fetchVideoDownloadURL({
          id: $downloadTarget.id,
          selectedDownloadSettings: selectedRadio.value,
        })

        if (downloadURL) {
          forceDownloadFile(downloadURL)
        }
        // }
      }

      /**
       * Скачивание избранного списка
       */
      if ($downloadTarget.target === FAVORITE_PHOTOS) {
        const downloadURL = await fetchFavoritePhotosDownloadURL({
          locationOrigin,
          favoritesListId: $downloadTarget.id,
          selectedDownloadSettings: selectedRadio.value,
        })

        if (selectedRadio.value === "web") {
          if (isMobile) {
            trackYandexMetrikaAction("click-radio-button-web-format-download-archive-mobile")
          }

          if (!isMobile) {
            trackYandexMetrikaAction("click-radio-button-web-format-download-archive-desktop")
          }
        }

        if (selectedRadio.value === "original") {
          if (isMobile) {
            trackYandexMetrikaAction("click-radio-button-original-format-download-archive-mobile")
          }

          if (!isMobile) {
            trackYandexMetrikaAction("click-radio-button-original-format-download-archive-desktop")
          }
        }

        if (downloadURL) {
          forceDownloadFile(downloadURL)
        }
      }
      handleCloseModal()
    }

    if (isConsumerEqYandex) {
      const radios = !isVideo && [...event.target.elements.type]
      const selectedRadio = !isVideo ? radios.find((radio) => Boolean(radio.checked)) : { value: "original" }

      if (hasIntentionToDownloadGallery) {
        const hasSeveralScenesToDownload = Array.isArray(selectedScenes)

        if (isMobile) {
          trackYandexMetrikaAction("click-download-archive-yandeks-disk-mobile-menu")
        }

        if (!isMobile) {
          trackYandexMetrikaAction("click-download-archive-yandeks-disk-desktop")
        }

        if (hasSeveralScenesToDownload) {
          await downloadToYandex({
            target: MULTIPLY_SCENES,
            targetId: selectedScenes,
            selectedPhotoType: selectedRadio.value,
          })
        }

        if (!hasSeveralScenesToDownload) {
          await downloadToYandex({
            target: $downloadTarget.target,
            targetId: [$downloadTarget.id],
            selectedPhotoType: selectedRadio.value,
          })
        }

        return
      }

      if (isMobile) {
        trackYandexMetrikaAction("click-download-photo-yandeks-disk-mobile")
      }

      if (!isMobile) {
        trackYandexMetrikaAction("click-download-photo-yandeks-disk-desktop")
      }

      await downloadToYandex({
        target: $downloadTarget.target,
        targetId: [$downloadTarget.id],
        selectedPhotoType: selectedRadio.value,
      })
    }

    if (isConsumerEqGoogle) {
      const radios = !isVideo && [...event.target.elements.type]
      const selectedRadio = !isVideo ? radios.find((radio) => Boolean(radio.checked)) : { value: "original" }

      if (isMobile) {
        trackYandexMetrikaAction("click-download-google-drive-mobile")
      }

      if (!isMobile) {
        trackYandexMetrikaAction("click-download-google-drive-desktop")
      }

      if (hasIntentionToDownloadGallery) {
        const hasSeveralScenesToDownload = Array.isArray(selectedScenes)

        if (!accessToken.current) {
          $setDownloadToCloudStatus(DOWNLOAD_ERROR)

          return
        }

        if (hasSeveralScenesToDownload) {
          $setDownloadToCloudStatus(DOWNLOAD_LOADING)

          const response = await downloadToCloud({
            entityType: MULTIPLY_SCENES,
            ids: selectedScenes,
            token: accessToken.current,
            type: selectedRadio.value,
            provider: DOWNLOAD_PROVIDER_GOOGLE,
          })

          if (response?.success) {
            $setDownloadToCloudStatus(DOWNLOAD_SUCCESS)
          } else {
            $setDownloadToCloudStatus(DOWNLOAD_ERROR)
          }

          return
        }
      }

      if (accessToken.current) {
        $setDownloadToCloudStatus(DOWNLOAD_LOADING)

        const response = await downloadToCloud({
          entityType: $downloadTarget.target === FAVORITE_PHOTOS ? FAVORITE_LIST : $downloadTarget.target,
          ids: [$downloadTarget.id],
          token: accessToken.current,
          type: selectedRadio.value,
          provider: DOWNLOAD_PROVIDER_GOOGLE,
        })

        if (response?.success) {
          $setDownloadToCloudStatus(DOWNLOAD_SUCCESS)
        } else {
          $setDownloadToCloudStatus(DOWNLOAD_ERROR)
        }
      }
    }
  }

  function handleCloseModal() {
    $hideDownloadModal()
    $resetDownloadToCloudStatus()
    $resetSelectedPhotoType()
    setIsDownloadToGoogleEnable(false)
    disableGoogleDownloadType()
  }

  function enableDownloadToGoogle() {
    setIsDownloadToGoogleEnable(true)
  }

  const isCrossIconVisible = !hasIntentionToDownloadGallery && $downloadToCloudStatus !== DOWNLOAD_LOADING
  const isGoBackButtonVisible = hasIntentionToDownloadGallery && $downloadToCloudStatus !== DOWNLOAD_LOADING
  const [totalWeight, scenesWeights] = useScenesWeight(
    hasIntentionToDownloadGallery ? gallery.scenes : [],
    downloadType
  )

  // const isDownloadToGoogleEnableForCurrentUser = isClient && Boolean(localStorage.getItem("isDownloadToGoogleEnable"))

  const [selectedScenes, setSelectedScenes] = useState<string[]>([])
  const selectedScenesWeight =
    downloadType === "original" && hasIntentionToDownloadGallery
      ? selectedScenes.reduce((acc, sceneId) => acc + scenesWeights.get(sceneId), 0) || totalWeight
      : null

  return (
    <Modal
      open={$isDownloadModalVisible}
      isCrossIconVisible={isCrossIconVisible}
      isGoBackButtonVisible={isGoBackButtonVisible}
      onClose={handleCloseModal}
      wrapperClassName={cx(hasIntentionToDownloadGallery && styles["modal-wrapper"])}
      modalClassName={cx(hasIntentionToDownloadGallery && styles["modal-inner"])}
    >
      {$downloadToCloudStatus === null && (
        <div className={styles["root"]}>
          <h2 className={cx(styles["title"], hasIntentionToDownloadGallery && styles["title-secondary"])}>
            {i18n.t("downloadModal.title")}
          </h2>
          <form onSubmit={handleSubmit}>
            {hasIntentionToDownloadGallery && (
              <SceneSelector onChange={setSelectedScenes} totalWeight={totalWeight} scenesWeights={scenesWeights} />
            )}
            {hasIntentionToDownloadGallery && (
              <div className={styles["download-consumer-caption"]}>
                <p className={cx(styles["subtitle"], styles["subtitle-secondary"])}>
                  {i18n.t("downloadModal.subtitleSecondary")}
                </p>
              </div>
            )}
            <ul
              className={cx(styles["consumers"], {
                [styles["grid"]]: hasIntentionToDownloadGallery,
              })}
            >
              {downloadOptions.device && (
                <li className={styles["item"]}>
                  <DownloadConsumerRadio
                    onClick={disableGoogleDownloadType}
                    className={styles["consumer"]}
                    id="0"
                    type="radio"
                    name="consumer"
                    defaultChecked={true}
                    value={DOWNLOAD_CONSUMER_DEVICE}
                    text={i18n.t("downloadModal.types.onDevice")}
                    icon={
                      <IconWrapper
                        light={<TabletDevice className={styles["mark-icon"]} width="18" height="15" />}
                        dark={<TabletDevice stroke="#fff" className={styles["mark-icon"]} width="18" height="15" />}
                      />
                    }
                  ></DownloadConsumerRadio>
                </li>
              )}

              {downloadOptions.yandex && (
                <li className={styles["item"]}>
                  <DownloadConsumerRadio
                    onClick={disableGoogleDownloadType}
                    className={styles["consumer"]}
                    id="1"
                    type="radio"
                    name="consumer"
                    defaultChecked={!downloadOptions.device}
                    value={DOWNLOAD_CONSUMER_YANDEX}
                    text={i18n.t("downloadModal.types.inYandexDisc")}
                    icon={
                      <IconWrapper
                        light={<YandexDisc className={styles["mark-icon"]} width="20" height="13" />}
                        dark={<YandexDiscLight className={styles["mark-icon"]} width="20" height="13" />}
                      />
                    }
                  ></DownloadConsumerRadio>
                </li>
              )}

              {downloadOptions.google && (
                <li className={styles["item"]}>
                  <DownloadConsumerRadio
                    onClick={enableGoogleDownloadType}
                    className={styles["consumer"]}
                    id="2"
                    type="radio"
                    name="consumer"
                    defaultChecked={!downloadOptions.device && !downloadOptions.yandex}
                    value={DOWNLOAD_CONSUMER_GOOGLE}
                    text={i18n.t("downloadModal.types.inGoogleDrive")}
                    icon={
                      <IconWrapper
                        light={<GoogleDrive className={styles["mark-icon"]} width="18" height="15" />}
                        dark={<GoogleDrive fill="#fff" className={styles["mark-icon"]} width="18" height="15" />}
                      />
                    }
                    loading={!isDownloadToGoogleEnable}
                  ></DownloadConsumerRadio>
                </li>
              )}
            </ul>
            {!isVideo && (
              <>
                <div className={styles["caption"]}>
                  <h2 className={cx(styles["subtitle"], hasIntentionToDownloadGallery && styles["subtitle-secondary"])}>
                    {i18n.t("downloadModal.subtitle")}
                  </h2>
                  <Hint position="right" text={i18n.t("downloadModal.hint")}></Hint>
                </div>
                <div className={styles["options"]}>
                  <Radio
                    className={styles["option"]}
                    name="type"
                    value="web"
                    onChange={(e) => handleChangeDownloadType(e)}
                    text={i18n.t("downloadModal.webFormat")}
                  ></Radio>
                  <Radio
                    className={styles["option"]}
                    name="type"
                    value="original"
                    onChange={(e) => handleChangeDownloadType(e)}
                    text={i18n.t("downloadModal.originalFormat")}
                    defaultChecked
                  ></Radio>
                </div>
              </>
            )}

            <Button
              className={cx(styles["submit-button"], {
                [styles["hidden-button"]]: isGoogleDownloadTypeActive,
              })}
              ref={submitRef}
              type="submit"
            >
              {textDownload}
              {selectedScenesWeight !== null && (
                <span className={styles["download-button-weight"]}>
                  &nbsp;(
                  <FormatSizeWithUnits size={selectedScenesWeight} />)
                </span>
              )}
            </Button>

            <iframe
              onLoad={enableDownloadToGoogle}
              scrolling="no"
              className={cx(styles["iframe"], {
                [styles["visible"]]: isGoogleDownloadTypeActive,
              })}
              frameBorder="0"
              // TODO: Тестируем айфрэйм, было так: ${getProtocol()}
              src={
                isClient &&
                `https://${
                  domain.split(".").length <= 3
                    ? domain.split(".").slice(-2).join(".")
                    : domain.split(".").slice(-3).join(".")
                }/verification/google-auth/?origin=${window.origin}&locale=${locale}&theme=${
                  gallery.theme.handle
                }&type=${$downloadTarget?.target}&size=${selectedScenesWeight}`
              }
            ></iframe>
          </form>
        </div>
      )}

      <DownloadResult $downloadToCloudStatus={$downloadToCloudStatus}></DownloadResult>
    </Modal>
  )
}

export { DownloadModal }
