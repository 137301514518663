import { api } from "@app/api"
import { REQUEST_TYPE } from "@app/api/constants"
import { getGalleryPasswordFromCookies } from "@app/features/gallery/utils"

type Args = {
  requestType: REQUEST_TYPE
  domain: string
  slug: any
  ctx?: any
  password?: string
}

async function fetchGallery({ requestType, domain, slug, ctx, password }: Args) {
  const galleryPassword = password || getGalleryPasswordFromCookies(domain, slug, ctx)

  try {
    const { data } = await api(requestType, ctx).get(`/published/galleries/${domain}/${slug}/`, {
      headers: {
        ...(galleryPassword && { "X-GALLERY-PASSWORD": galleryPassword }),
      },
    })

    // Данные для публичной галереи
    if (data.success && data.code === "200") {
      return { gallery: data.data, isPasswordProtected: false }
    }

    // Данные для приватной галереи
    if (data.success && data.code === "206") {
      return { gallery: data.data, isPasswordProtected: true }
    }
  } catch (error) {
    console.error(error.response)

    return {
      gallery: null,
      isPasswordProtected: true,
    }
  }
}

export { fetchGallery }
