import { TRACKING_ID } from "@app/features/analytics/constants"

type Action =
  | "click-menu"
  | "click-download"
  | "click-share"
  | "click-radio-button-web-format-download-archive-desktop"
  | "click-radio-button-web-format-download-archive-mobile"
  | "click-radio-button-original-format-download-archive-desktop"
  | "click-radio-button-original-format-download-archive-mobile"
  | "click-radio-button-web-format-download-photo-full-screen-desktop"
  | "click-radio-button-web-format-download-photo-full-screen-mobile"
  | "click-radio-button-original-format-download-photo-full-screen-desktop"
  | "click-radio-button-original-format-download-photo-full-screen-mobile"
  | "click-radio-button-original-format-download-video-full-screen-desktop"
  | "click-radio-button-original-format-download-video-full-screen-mobile"
  | "click-download-archive-yandeks-disk-desktop"
  | "click-download-archive-yandeks-disk-mobile-menu"
  | "click-download-archive-google-drive-desktop"
  | "click-download-archive-google-drive-mobile-menu"
  | "click-download-photo-yandeks-disk-desktop"
  | "click-download-photo-yandeks-disk-mobile"
  | "click-download-photo-google-drive-desktop"
  | "click-download-photo-google-drive-mobile"
  | "click-hover-download-photo-desktop"
  | "click-download-photo-full-screen-desktop"
  | "click-download-photo-full-screen-mobile"
  | "click-download-archive-google-drive-desktop"
  | "click-download-archive-google-drive-mobile-menu"
  | "click-download-photo-google-drive-desktop"
  | "click-download-photo-google-drive-mobile"
  | "click-header-download-photo-desktop"
  | "click-favorites-download-photo-desktop"
  | "click-favorites-download-photo-mobile"
  | "click-download-google-drive-desktop"
  | "click-download-google-drive-mobile"
  | "click-header-favorites-desktop"
  | "click-menu-favorites-mobile"
  | "click-сomplete-photo-selection-mobile"
  | "click-сomplete-photo-selection-desktop"
  | "click-comment-favorites-desktop"
  | "click-comment-favorites-mobile"
  | "click-share-archive-desktop"
  | "click-hover-share-photo-desktop"
  | "click-share-photo-full-screen-desktop"
  | "click-share-photo-full-screen-mobile"
  | "GoToLayout"
  | "GenerateLayout"
  | "CustomLayout"
  | "BookOrder"
  | "BookCheckout"

function trackYandexMetrikaAction(action: Action) {
  try {
    window["ym"](TRACKING_ID.YANDEX_METRIKA, "reachGoal", action)
  } catch (error) {}
}

export { trackYandexMetrikaAction }
