import React from "react"
import cx from "clsx"

import { Question } from "ui/icons"
import { Tooltip } from "ui/tooltip"
import { IconWrapper } from "@app/features/theme/icon-wrapper"

import styles from "./hint.module.scss"

function Hint({ text, position }) {
  return (
    <Tooltip position={position} className={styles["hint"]} text={text}>
      <IconWrapper
        light={<Question width="20" height="20" fill="#DEDEDE" />}
        dark={<Question width="20" height="20" fill="#313131" pathFill="#fff" />}
      />
    </Tooltip>
  )
}

export { Hint }
