import cx from "clsx"

import { Check } from "@app/ui/icons"
import { IconWrapper } from "@app/features/theme/icon-wrapper"

import styles from "./checkbox.module.scss"
import { FC, PropsWithChildren } from "react"

interface CheckboxProps {
  text?: string
  className?: string
  quantity?: number // TODO: Этого тут быть не должно
  textStyle?: "bold"
  onChange?: (name: any) => void
  [key: string]: any
}

const Checkbox: FC<PropsWithChildren<CheckboxProps>> = ({
  text,
  className,
  quantity,
  textStyle,
  onChange,
  checked,
  register,
  children,
  ...props
}) => {
  const isQuantityExist = quantity !== undefined // TODO: Этого тут быть не должно

  return (
    <label className={cx(styles["root"], className)}>
      <input
        type="checkbox"
        className={styles["input"]}
        onChange={onChange}
        checked={checked}
        ref={register}
        {...props}
      ></input>
      <span className={styles["mark-icon"]}>
        <IconWrapper light={<Check width={14} height={10} />} dark={<Check stroke="#fff" width={14} height={10} />} />
      </span>
      {text && <span className={cx(styles["text"], textStyle === "bold" && styles["bold"])}>{text}</span>}
      {isQuantityExist && <span className={styles["quantity"]}>{quantity}</span>}
      {children}
    </label>
  )
}

export { Checkbox }
