import React from "react"
import cx from "clsx"

import { Spinner } from "@app/ui/spinner"

import styles from "./download-consumer.module.scss"

const DownloadConsumerRadio = ({
  id,
  value,
  type = "radio",
  name,
  text,
  className,
  icon,
  onClick,
  defaultChecked,
  loading = false,
  ...rest
}: any) => {
  return (
    <div
      className={cx(
        styles["root"],
        {
          [styles["loading"]]: loading,
        },
        className
      )}
      onClick={onClick}
      {...rest}
    >
      <input
        className={styles["radio"]}
        type={type}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        id={id}
      ></input>

      <label className={styles["label"]} htmlFor={id}>
        {!loading && (
          <React.Fragment>
            {icon}
            <span>{text}</span>
          </React.Fragment>
        )}
        {loading && <Spinner></Spinner>}
      </label>
    </div>
  )
}

export { DownloadConsumerRadio }
